/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 14/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


$BACKGROUND_OPACITY: #F1F3F215;

$SUCCESS: #77bb43;
$ERROR: #FF5454;
$WARNING: #FFBF57;

$DARK_GREY: #444444;
$GREY: #CCCCCC;
$LIGHT_GREY: #EEEEEE;
$BORDER_GREY: #CDCDCD;
$WHITE: #FFFFFF;
$DARK_BLUE: #364252;
$BLUE: #175097;
$GREEN: #14B47C;
$GREEN_HOVER: rgba(20, 180, 124, 0.5);
$LIGHT_GREEN: #73D2C4;
$YELLOW: #FDD961;
$YELLOW_HOVER: rgba(253, 217, 97, 0.5);
$PEACH: #FE9D8D;

$RED: #FF5454;
$RED_HOVER: rgba(255, 84, 84, 0.51);
