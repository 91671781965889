@import "styles/styles";

.input_wrapper {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .label {
    font-family: "neuzeit-grotesk", sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.54);
  }

  .label_disabled {
    color: rgba(0, 0, 0, 0.38);
  }

  .autocomplete_wrapper {
    width: 100%;


    .input {
      width: 100%;
      position: relative;
      border: 1px solid $BORDER_GREY;
      border-radius: 4px;
      padding: 14px 9px;
      font-size: 16px;
      outline: none;
    }

    .input_disabled {
      background: transparent;
      color: unset;
      border: none;
      padding: 9px 0px 0px;
    }

    .suggestions_container {
      z-index: 1400;
      background-color: $WHITE;
      border-radius: 2px;
      box-shadow: 0 2px 6px rgba(0,0,0,0.3);
      position: absolute;

      .suggestion {
        cursor: pointer;
        padding: 2px;
      }

      .suggestion:hover {
        background-color: $LIGHT_GREY;
      }
    }
  }
}
:global(.MuiInputBase-input){
  line-height: 1!important;
}

input{
  line-height: 1!important;
}
