/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
@import "styles/styles";


.member {
    width: 100%;
    position: relative;
    padding: 18px;

    .form {
        width: 100%;
        position: relative;
        padding: 18px 0 36px;

        .form_row {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 9px;

            > :nth-child(1n) {
                margin-right: 9px;
                flex: 1;
                align-self: baseline;
            }

            > :only-child {
                width: 100%;
            }

            .label {
                font-family: "neuzeit-grotesk", sans-serif;
                font-size: 15px;
                font-weight: 700;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.54);
              }
        }
        .merch_container{
            border:1px solid $GREY;
            border-radius: 4px;
            padding: 18px;
            box-sizing: border-box;
            margin: 9px 18px 9px 9px;
            width: unset;

            span{
                font-weight: 700;
            }
        }

        .form_row_full {
            @extend .form_row;

            width: 100%;
        }
    }

    .warning {
        background-color: $WARNING;
        padding: 4px;
    }

}

@media screen and (max-width: 640px){
    .member{
        .form{
            .form_row{
                flex-direction: column;
                align-items: flex-start;
                p{
                    margin: 0px 0px 9px 0px;
                }
            }
        }
    }
}