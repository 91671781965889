/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

@import "styles/styles";

html, body, #root {
    position: relative;
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    color: $DARK_GREY;
    background-color: #FFFFFF;
    font-family: "neuzeit-grotesk", sans-serif;
    font-size: 15px;
    line-height: 25px;
}

body {
    font-family: 'neuzeit-grotesk', sans-serif;
}

h1 {
    font-family: 'Sanchez', serif;
    font-size: 40px;
    font-weight: 300;
}

h2 {
    font-size: 32px;
    font-weight: 300;
    font-family: 'Sanchez', serif;
    margin: 0 0 27px;

}

h3 {
    font-size: 26px;
    font-family: 'Sanchez', serif;
    font-weight: 300;
    line-height: 44px;


}

h4 {
    font-size: 22px;
    font-family: 'neuzeit-grotesk', sans-serif;
    line-height: 31px;
    font-weight: 300;
}

h5 {
    font-size: 18px;
    font-family: 'Sanchez', serif;
    line-height: 24px;
    font-weight: 300;
}
h6{
    font-size: 13px;
    font-family: 'neuzeit-grotesk', sans-serif;
    line-height: 19px;
    font-weight: 300;
    margin: 0;
}


p {
    font-family: "neuzeit-grotesk", sans-serif;
    font-size: 19px;
    line-height: 29px;
}
a{
    cursor: pointer;
    text-decoration: underline;
}
