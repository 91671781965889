/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 11/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


.filter {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    > :nth-child(1n) {
        margin-right: 9px;
    }

    .reset_date_range {

    }
}