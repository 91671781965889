/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-06-06.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
@import "styles/colours";

.date_time_picker_wrapper {
  //box-shadow: $BOX_SHADOW;
  //background-color: white;
  //border-radius: 9px;
  //font-size: 14px;
  //text-transform: none;
  //line-height: 21px;
  //height: 32px;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //width: max-content;
  //position: relative;

  //.disabled_container {
  //  position: absolute;
  //  display: flex;
  //  top: 0;
  //  bottom: 0;
  //  left: 0;
  //  right: 0;
  //  background-color: white;
  //  border-radius: 9px;
  //  padding: 0 9px;
  //  font-size: 14px;
  //  align-items: center;
  //}
  //
  //&:hover {
  //  background-color: rgba(0, 0, 0, 0.08);
  //}
  //
  //.date_time_picker {
  //  flex: 1;
  //
  //  > div {
  //    width: 100%;
  //    font-size: 14px;
  //    cursor: pointer;
  //    padding: 0 9px;
  //
  //    > input {
  //      cursor: pointer;
  //    }
  //  }
  //
  //  > p {
  //    display: none;
  //  }
  //
  //  &.disabled {
  //
  //  }
  //}
}

.date_time_picker_popover {
  color: white;


  :global(.MuiPickersToolbarText-toolbarTxt), :global(.MuiPickersToolbarText-toolbarBtn) {
    color: #e1e1e1;
  }

  :global(.MuiPickersToolbarText-toolbarTxtSelected), :global(.MuiPickersToolbarText-toolbarBtnSelected) {
    color: white;
  }



  :global(.MuiPickersBasePicker-container) {

    :global(.MuiPickersClockPointer-thumb) {
      border-color: $PEACH;
    }

    :global(.MuiPickersDay-current) {
      color: white;
      background-color: $GREEN_HOVER;
    }

    :global(.MuiPickersDay-daySelected),
    :global(.MuiPickersClockPointer-pointer),
    :global(.MuiPickersClockNumber-clockNumberSelected) {
      background-color: $PEACH;
      color: white;
    }
  }

}
