/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 9/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

@import "styles/styles";

.subscriptions_overview_nav {
    width: 100%;

    button {
        text-transform: none;
        border-radius: 4px;
        font-size: 18px;
        font-family: 'Sanchez', serif;
    }

    .tab_selected {
        border: 1px solid #EEEEEE;
        border-bottom: 3px solid $PEACH;
    }
}

.content {

    padding: 18px 0;
    width: 100%;
}

.warning {
    background-color: $WARNING;
    padding: 4px;
}