/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 5/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

@import "styles/styles";

.login {
    background-color: $BLUE;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            margin: 54px;
        }

        .card {
            max-width: 567px;
            min-width: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 54px;

            .form {
                padding-top: 36px;
                display: flex;
                flex-direction: column;
                > * {
                    margin-bottom: 18px;
                }
            }
        }
    }
}