@import "styles/styles";

.button {
  height: auto;
  min-height: 44px;
  color: $DARK_GREY;
  background-color: transparent;
  box-shadow: none;
}

.red_plain{
  font-size: 15px!important;
  min-height: 27px!important;
  color: $RED!important;
  background-color: transparent!important;
  text-decoration: underline!important;
}

.green {

  background-color: $GREEN;
  color: $WHITE;

  &:hover {
    background-color: $GREEN_HOVER;
  }
}

.red {

  background-color: $RED;
  color: $WHITE;

  &:hover {
    background-color: $RED_HOVER;
  }
}

.yellow {
  background-color: $YELLOW;
  color: $DARK_GREY;

  &:hover {
    background-color: $YELLOW_HOVER;
  }
}