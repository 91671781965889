/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

@import "styles/styles";

.filter {
    display: flex;
    flex-direction: row;
    padding-bottom: 18px;

    > :nth-child(1n) {
        margin-right: 9px;
    }

}

.table_link {
    cursor: pointer;
    color: $GREEN;

    &:hover {
        color: $GREEN_HOVER;
    }
}