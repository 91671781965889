/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 3/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;


    .content {
        box-sizing: border-box;
        width: 100%;
        border-bottom: 1px solid #CCDDCC;
        padding: 0px 0 9px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text {
            flex: 1;
            height: 30px;
            display: flex;
            align-items: center;

            > h3 {
                margin: 0;
            }
        }

        .action {
            > :nth-child(1n) {
                margin-left: 9px;
            }
        }
    }
}

.large {
    .content {
        .text {
            > h3 {
                font-size: 36px;
            }
        }
    }

    padding-bottom: 18px;
}