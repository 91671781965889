/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 5/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
@import "styles/styles";


.dashboard {
    height: 100%;
    width: 100%;
    position: relative;
    //display: flex;
    //flex-direction: column;

    .toolbar {

        border-bottom: 1px solid $LIGHT_GREY;

        .toolbar_inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 18px 36px;
            width: 100%;
            max-width: 1152px;
            margin: 0 auto;

            .logo {

            }
        }
    }

    .dashboard_inner {
        width: 100%;
        max-width: 1152px;
        min-height: calc(100% - 81px);
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin: 0 auto;
        padding: 36px 27px;

        .sidebar {
            width: 20%;
            padding-right: 9px;
            box-sizing: border-box;

            a{
                font-family: 'Sanchez', serif;
                font-size: 15px;
                border-bottom: 1px solid transparent;

            }
            a:hover, a:focus {
                border-bottom: 1px solid $GREEN;
            }

        }
        .content {
            width: 76%;
            h3{
                margin-top: 0px;
                margin-bottom: 36px;
            }
        }
    }
}