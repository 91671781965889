/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 20/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

.dialog {
    .title {
        padding: 18px;
    }

    .content {
        padding: 9px 18px;

        > :not(:last-child) {
            margin-bottom: 9px;
        }
    }

    .buttonsRight {
        > :not(:first-child) {
            margin-left: 9px;
        }
    }

    .buttons {
        justify-content: space-between;
        padding: 18px;

        > :not(:first-child) {
            margin-left: 9px;
        }
    }
}
