/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 10/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


.paging_table {
    background-color: transparent;

    :global(.MuiToolbar-root) {
        padding: 9px;


        :global(.MuiTablePagination-select) {
            min-width: auto;
        }
    }

    table {
        margin-top: 36px;
        thead {
            tr {
                th {
                    font-weight: 700;
                    padding: 9px;
                    background-color: transparent;
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 9px;
                }
            }
        }

        tfoot {
            td {
                border-bottom: none;
            }
        }
    }
}